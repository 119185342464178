import Vue from "vue";
import store from "./store";
import Router from "vue-router";
import axios from "axios";
import userLevels from './user_levels'

const routes = [];
routes.push({
  path: "*",
  component: () => import("./pages/NotFound.vue"),
  name: "Não Encontrado",
});
routes.push({
  path: "/home",
  component: () => import("./pages/Home.vue"),
  name: "Home",
  meta: {
    name: "Página Inicial",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/loginforced/:token",
  component: () => import("./pages/Home.vue"),
  name: "Login como usuário",
});
routes.push({
  path: "/login",
  component: () => import("./pages/login/Login.vue"),
  name: "Login",
});
routes.push({
  path: "/whitelabels",
  component: () => import("./pages/Whitelabels/List.vue"),
  name: "WhiteLabels",
  meta: {
    name: "Whitelabels",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
    ],
  },
});
routes.push({
  path: "/makes",
  component: () => import("./pages/Makes/List.vue"),
  name: "Marcas de Carro",
  meta: {
    name: "Marcas de Carro",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
    ],
  },
});
routes.push({
  path: "/condominiums",
  component: () => import("./pages/Condominiums/List.vue"),
  name: "Condomínios",
  meta: {
    name: "Condomínios",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});
routes.push({
  path: "/phone-extensions",
  component: () => import("./pages/Condominiums/PhoneExtensions/List.vue"),
  name: "PhoneExtensions",
  meta: {
    name: "Ramais",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});
routes.push({
  path: "/modules",
  component: () => import("./pages/Modules/List.vue"),
  name: "Módulos",
  meta: {
    name: "Módulos",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
    ],
  },
});
routes.push({
  path: "/users",
  component: () => import("./pages/Users/List.vue"),
  name: "UsersList",
  meta: {
    name: "Usuários",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
    ],
  },
});
routes.push({
  path: "/users/new",
  component: () => import("./pages/Users/Form.vue"),
  name: "UserNew",
  meta: {
    name: "Criar Usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  },
});
routes.push({
  path: "/users/:id/edit",
  component: () => import("./pages/Users/Form.vue"),
  name: "UserEdit",
  meta: {
    name: "Editar Usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  },
});
routes.push({
  path: "/condominiums/:condominiumId/users",
  component: () => import("./pages/Users/List.vue"),
  name: "CondominiumUsersList",
  meta: {
    name: "Usuários do Condomínio",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ]
  }
});
routes.push({
  path: "/condominiums/:condominiumId/users/new",
  component: () => import("./pages/Users/Form.vue"),
  name: "CondominiumUserNew",
  meta: {
    name: "Criar Usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  },
});
routes.push({
  path: "/condominiums/:condominiumId/users/:id/edit",
  component: () => import("./pages/Users/Form.vue"),
  name: "CondominiumUserEdit",
  meta: {
    name: "Editar Usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  },
});
routes.push({
  path: "/users/addresses",
  component: () => import("./pages/Users/AddressesList.vue"),
  name: "UserGeneralAddressList",
  meta: {
    name: "Endereços Registrados",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  }
});
routes.push({
  path: "/users/:addressUserId/addresses",
  component: () => import("./pages/Users/AddressesList.vue"),
  name: "UserAddressList",
  meta: {
    name: "Lista de endereços de usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  }
});
routes.push({
  path: "/users/:addressUserId/addresses/new",
  component: () => import("./pages/Users/AddressForm.vue"),
  name: "UserAddressNew",
  meta: {
    name: "Adicionar endereço para usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  }
});
routes.push({
  path: "/users/:addressUserId/addresses/:id/edit",
  component: () => import("./pages/Users/AddressForm.vue"),
  name: "UserAddressEdit",
  meta: {
    name: "Editar endereço de usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  }
});
routes.push({
  path: "/users/:addressUserId/addresses/:id/authorized-times",
  component: () => import("./pages/Users/AddressTimeForm.vue"),
  name: "UserAddressEmployeeTimeSet",
  meta: {
    name: "Permissões de acesso",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  }
});

routes.push({
  path: "/users/:userId/addresses/:addressId/vehicles",
  component: () => import("./pages/Vehicles/List.vue"),
  name: "UserVehicleList",
  meta: {
    name: "Lista de veículos de usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  }
});
routes.push({
  path: "/users/:userId/addresses/:addressId/vehicles/new",
  component: () => import("./pages/Vehicles/Form.vue"),
  name: "UserVehicleNew",
  meta: {
    name: "Adicionar veículo para usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  }
});
routes.push({
  path: "/users/:userId/addresses/:addressId/vehicles/:id/edit",
  component: () => import("./pages/Vehicles/Form.vue"),
  name: "UserVehicleEdit",
  meta: {
    name: "Editar veículo de usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  }
});

routes.push({
  path: "/users/:userId/addresses/:addressId/pets",
  component: () => import("./pages/Pets/List.vue"),
  name: "UserPetList",
  meta: {
    name: "Lista de pets de usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  }
});
routes.push({
  path: "/users/:userId/addresses/:addressId/pets/new",
  component: () => import("./pages/Pets/Form.vue"),
  name: "UserPetNew",
  meta: {
    name: "Adicionar pet para usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ]
  }
});
routes.push({
  path: "/users/:userId/addresses/:addressId/pets/:id/edit",
  component: () => import("./pages/Pets/Form.vue"),
  name: "UserPetEdit",
  meta: {
    name: "Editar pet de usuário",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  }
});

routes.push({
  path: "/visitors",
  component: () => import("./pages/Visitors/List.vue"),
  name: "Visitors",
  meta: {
    name: "Visitantes",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});

routes.push({
  path: "/access",
  component: () => import("./pages/Modules/Access/Main.vue"),
  name: "AccessControl",
  meta: {
    name: "Controle de Acesso",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/announcements",
  component: () => import("./pages/Modules/Announcements/List.vue"),
  name: "Announcements",
  meta: {
    name: "Comunicados",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/announcements-general",
  component: () => import("./pages/Modules/Announcements/List.vue"),
  name: "AnnouncementsGeneral",
  meta: {
    name: "Comunicados (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});

routes.push({
  path: "/news",
  component: () => import("./pages/Modules/News/List.vue"),
  name: "News",
  meta: {
    name: "Notícias",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/news-general",
  component: () => import("./pages/Modules/News/List.vue"),
  name: "NewsGeneral",
  meta: {
    name: "Notícias (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});

routes.push({
  path: "/meetings",
  component: () => import("./pages/Modules/Meetings/List.vue"),
  name: "Meetings",
  meta: {
    name: "Assembléias",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/meetings-general",
  component: () => import("./pages/Modules/Meetings/List.vue"),
  name: "MeetingsGeneral",
  meta: {
    name: "Assembléias (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});

routes.push({
  path: "/contacts",
  component: () => import("./pages/Modules/Contacts/List.vue"),
  name: "Contacts",
  meta: {
    name: "Fale com a Administração",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/contacts-general",
  component: () => import("./pages/Modules/Contacts/List.vue"),
  name: "ContactsGeneral",
  meta: {
    name: "Fale com a Administração (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});

routes.push({
  path: "/occourences",
  component: () => import("./pages/Modules/Occourences/List.vue"),
  name: "Occourences",
  meta: {
    name: "Livro de Ocorrências",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/occourences-general",
  component: () => import("./pages/Modules/Occourences/List.vue"),
  name: "OccourencesGeneral",
  meta: {
    name: "Livro de Ocorrências (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});

routes.push({
  path: "/documents",
  component: () => import("./pages/Modules/Documents/View.vue"),
  name: "Documents",
  meta: {
    name: "Documentos",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/documents-general",
  component: () => import("./pages/Modules/Documents/View.vue"),
  name: "DocumentsGeneral",
  meta: {
    name: "Documentos (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});

routes.push({
  path: "/preenchimento/dados",
  component: () => import("./pages/Fill/Index.vue"),
  name: "Preenchimento de dados de entrada",
});

routes.push({
  path: "/packages",
  component: () => import("./pages/Modules/Packages/List.vue"),
  name: "Packages",
  meta: {
    name: "Encomendas",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});

routes.push({
  path: "/packages-general",
  component: () => import("./pages/Modules/Packages/List.vue"),
  name: "PackagesGeneral",
  meta: {
    name: "Encomentas (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});

routes.push({
  path: "/banners",
  component: () => import("./pages/Banners/List.vue"),
  name: "Banners",
  meta: {
    name: "Banners",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});

routes.push({
  path: "/reports",
  component: () => import("./pages/Modules/Reports/List.vue"),
  name: "Reports",
  meta: {
    name: "Relatórios",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/reports-general",
  component: () => import("./pages/Modules/Reports/List.vue"),
  name: "ReportsGeneral",
  meta: {
    name: "Relatórios (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});

routes.push({
  path: "/internal-ads",
  component: () => import("./pages/Modules/InternalAds/List.vue"),
  name: "InternalAds",
  meta: {
    name: "Anúncios Internos",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/internal-ads-general",
  component: () => import("./pages/Modules/InternalAds/List.vue"),
  name: "InternalAdsGeneral",
  meta: {
    name: "Anúncios Internos (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});
routes.push({
  path: "/reservations",
  component: () => import("./pages/Modules/Reservations/List.vue"),
  name: "Reservations",
  meta: {
    name: "Reservas de Espaços",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/reservations-general",
  component: () => import("./pages/Modules/Reservations/List.vue"),
  name: "ReservationsGeneral",
  meta: {
    name: "Reservas Espaços (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});
routes.push({
  path: "/surveys",
  component: () => import("./pages/Modules/Surveys/List.vue"),
  name: "Surveys",
  meta: {
    name: "Enquetes",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
      userLevels.LEVEL_ADMIN_CONDOMINIUM,
      userLevels.LEVEL_CONDOMINIUM_EMPLOYEE,
    ],
  },
});
routes.push({
  path: "/surveys-general",
  component: () => import("./pages/Modules/Surveys/List.vue"),
  name: "SurveysGeneral",
  meta: {
    name: "Enquetes (Geral)",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  },
});
routes.push({
  path: "/cameraview/:id/:token",
  component: () => import("./pages/Cameras/ClientView.vue"),
  name: "Câmera - Visualização",
});


routes.push({
  path: "/migration",
  component: () => import("./pages/Migration.vue"),
  name: "MigrationPage",
  meta: {
    name: "Migração",
    levels: [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ],
  }
});

const router = new Router({
  history: true,
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

async function getUser() {
  await axios
    .get("/auth/me")
    .then(async (r) => {
      store.commit("setUser", r?.data?.user);
    })
    .catch((_) => {
      localStorage.removeItem("token_app");
      store.commit("setUser", null);
    });
}
async function getCondominium(id) {
  await axios
    .get("/condominiums", {
      params: {
        id,
        with: ["whitelabel", "camerasgroup.cameras"],
      }
    })
    .then(async (r) => {
      const condominium = r?.data?.items?.data?.find((c) => c.id == id);
      console.log({ condominium })
      if (condominium) {
        store.commit("setCondominiumId", id);
        store.commit("setCondominium", condominium);
      }
    })
    .catch((_) => {
      console.log({ _ })
    });
}
function afterEach(to) {
  setTimeout(() => {
    store.commit("setLoadingRoute", false);
  }, 500);
  Vue.nextTick(() => {
    const condominium = store.state?.condominium;
    const whitelabel = store.state?.whitelabel;
    const name = condominium?.name ?? whitelabel?.name ?? "VN3";
    document.title = `${name} | ${to?.meta?.name ?? to.name}`;
  });
}

function resetAxiosConfig() {
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token_app")}`,
  };
}

router.beforeEach(async (to, from, next) => {
  if (!store.state.user) {
    await getUser();
  }
  if (
    [
      userLevels.LEVEL_ADMIN_GENERAL,
      userLevels.LEVEL_ADMIN_WHITELABEL,
    ].includes(store.state.user?.level) &&
    localStorage.getItem("lastSelectedCondominium")
  ) {
    await getCondominium(Number(localStorage.getItem("lastSelectedCondominium")));
  }

  if (to.path == "/") {
    if (!store.state.user) {
      return next("/login");
    }
    return next("/home");
  }
  if (to.path.includes("/loginforced")) {
    let token = to.path.replace("/loginforced/", "");
    localStorage.setItem("token_app", token);
    resetAxiosConfig();
    window.location.href = "/home";
    return;
  }
  if (to.path.includes("/preenchimento/dados")) {
    return next();
  }
  if (to.path.includes("/cameraview")) {
    return next();
  }
  store.commit("setLoadingRoute", true);

  let user = store.state.user;

  if (!user) {
    await getUser();
    user = store.state.user;
  }

  /**
   * Todas as rotas necessitam de autenticação
   */
  if (to.path == "/login") {
    if (user) {
      if (from.path == "/") afterEach(to);
      return next("/home");
    }
  }
  if (user?.level == 0) {
    localStorage.removeItem("token_app");
    resetAxiosConfig();
    store.commit("setAccessDenied", true);
    setTimeout(() => {
      store.commit("setAccessDenied", false);
    }, 5000);
    afterEach(to);
    return next("/login");
  }
  if (to.meta?.levels) {
    if (to?.meta?.levels?.includes(user?.level)) {
      return next();
    } else {
      return next("/home");
    }
  }

  // otherwise allow user to access route, and check if logged and update user data if true.
  return next();
});
router.afterEach((to, from) => {
  store.commit("setPath", to.path);
  afterEach(to);
});
export default router;
